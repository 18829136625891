import { Toast } from "react-bootstrap";
import ReactMarkdown from "react-markdown";

function UserMessage({ message }) {
  return (
    <>
      <Toast style={{ width: "100%" }}>
        <Toast.Header closeButton={false}>
          <strong className="me-auto">User</strong>
        </Toast.Header>
        <Toast.Body>
          <ReactMarkdown children={message.text} />
        </Toast.Body>
      </Toast>
    </>
  );
}
export default UserMessage;
