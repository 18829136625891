import { Container, Button, Row, Col } from "react-bootstrap";

import Messages from "../message/Messages";
import FeedbackButtons from "./FeedbackButtons";
import DocDropdown from "./DocDropdown";
import { useContext, useEffect, useState, useRef } from "react";
import { ChatHttpClientContext } from "../../App";

function Chat() {
  const chatHttpClient = useContext(ChatHttpClientContext);

  const [brains, setBrains] = useState(null);
  const brainFetcher = useRef();
  const [activeBrains, setActiveBrains] = useState([]);

  const [userInput, setUserInput] = useState("");
  const userInputRef = useRef(null);

  const [messages, setMessages] = useState([]);
  const streamFetcher = useRef();
  const streamId = useRef(null);
  const isGenerating = streamId.current != null;

  const handleUserInputKeydown = (event) => {
    if (!(event.key === "Enter" && !event.shiftKey)) {
      return;
    }
    event.preventDefault();
    if (isGenerating) {
      return;
    }
    sendLLMRequest(false);
  };

  const extractHistory = (regenerate) => {
    // returns a history of chat messages where the last message contains the user prompt
    let textMsg = null;
    let history = [];

    if (!brains) {
      return null;
    }

    if (regenerate) {
      if (messages[messages.length - 2]?.origin !== "user" ?? false) {
        return null;
      }
      textMsg = messages[messages.length - 2].text;
      history = messages.slice(0, -2);
    } else {
      textMsg = userInput.trim();
      if (textMsg === "") {
        return null;
      }
      setUserInput("");
      history = messages;
    }

    return [
      ...history,
      {
        origin: "user",
        text: textMsg,
      },
    ];
  };

  const generateRandomString = () => {
    const randomBytes = new Uint8Array(16); // 128 bits = 16 bytes
    window.crypto.getRandomValues(randomBytes);

    return Array.from(randomBytes)
      .map((byte) => byte.toString(16).padStart(2, "0"))
      .join("");
  };

  const handleStreamResponse = (history, ragResponse, streamResponse) => {
    if (streamResponse.end) {
      clearInterval(streamFetcher.current);
      streamId.current = null;
    } else if (streamResponse.answer !== "") {
      streamResponse.answer += "⚫";
    }
    setMessages([
      ...history,
      {
        origin: "assistant",
        text: streamResponse.answer,
        duration_seconds: streamResponse.duration_seconds,
        end: streamResponse.end,
        sources: ragResponse?.sources ?? null,
      },
    ]);
  };

  const doRagRequest = async (history) => {
    streamId.current = generateRandomString();

    const ragRequest = {
      messages: history.map((message) => ({
        role: message.origin,
        content: message.text,
      })),
      brains: activeBrains.map((brain) => brain.id),
      stream_id: streamId.current,
    };

    const ragResponse = await chatHttpClient.requestRag(ragRequest);
    if (streamId.current !== ragResponse.stream_id) {
      return;
    }

    streamFetcher.current = setInterval(() => {
      chatHttpClient
        .fetchStream({
          stream_id: ragResponse.stream_id,
        })
        .then((streamResponse) =>
          handleStreamResponse(history, ragResponse, streamResponse)
        );
    }, 250);
  };

  const sendLLMRequest = (regenerate = false) => {
    const history = extractHistory(regenerate);
    if (!history) {
      return;
    }

    setMessages([
      ...history,
      {
        origin: "assistant",
        text: "",
        end: false,
      },
    ]);
    setTimeout(() => {
      // wait before scrolling for new messages to have appeared on screen
      const chatWindow = document.getElementById("chatWindow");
      chatWindow.scrollTo({ top: chatWindow.scrollHeight, behavior: "smooth" });
    }, 200);

    doRagRequest(history);
  };

  const handleStopGeneration = () => {
    delayedFocusUserInput();
    if (
      messages.length < 1 ||
      (messages[messages.length - 1]?.origin !== "assistant" ?? true)
    ) {
      return;
    }

    const stoppedMessages = [...messages];
    if (stoppedMessages[stoppedMessages.length - 1].text === "") {
      stoppedMessages[stoppedMessages.length - 1].text =
        "Error: Generation was stopped.";
    }
    stoppedMessages[stoppedMessages.length - 1].end = true;
    setMessages(stoppedMessages);
    clearInterval(streamFetcher.current);
    streamId.current = null;
  };

  const fetchBrains = () => {
    chatHttpClient.getBrains().then((brainResponse) => {
      if (brainResponse.brains === null) {
        return;
      }
      clearInterval(brainFetcher.current);
      setBrains(brainResponse.brains);
    });
  };
  useEffect(() => {
    brainFetcher.current = setInterval(fetchBrains, 1000);
  }, []);

  const focusUserInput = () => {
    if (brains) {
      userInputRef.current?.focus();
    }
  };
  useEffect(focusUserInput, [brains]);

  const delayedFocusUserInput = () =>
    setTimeout(() => {
      focusUserInput(); // wait before focussing to not interfer with react-bootstrap focus handling
    }, 200);

  return (
    <Container
      style={{
        padding: "1em",
        backgroundColor: "white",
        boxShadow:
          "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2)",
      }}
    >
      <Row>
        <DocDropdown
          brains={brains}
          activeBrains={activeBrains}
          setActiveBrains={setActiveBrains}
          onSwitch={() => setMessages([])}
          onHide={delayedFocusUserInput}
          disabled={isGenerating}
        />
      </Row>

      <Row style={{ height: "60vh" }}>
        <Col id="chatWindow" style={{ height: "60vh", overflowY: "scroll" }}>
          <Messages
            messages={messages}
            windowId={"chatWindow"}
            onHide={delayedFocusUserInput}
          />
        </Col>
      </Row>
      <Row>
        <Col className="text-right">
          <Button
            className="btn btn-primary btn-sm"
            style={{ marginTop: "1em", marginRight: "4px", float: "left" }}
            onClick={() => {
              delayedFocusUserInput();
              setMessages([]);
            }}
            disabled={messages.length === 0 || isGenerating}
          >
            Clear history
          </Button>
          <Button
            className="btn btn-primary btn-sm"
            style={{ marginTop: "1em", marginRight: "4px", float: "left" }}
            onClick={() => {
              delayedFocusUserInput();
              sendLLMRequest(true);
            }}
            disabled={messages.length === 0 || isGenerating}
          >
            Regenerate last response
          </Button>
          <Button
            className="btn btn-primary btn-sm"
            style={{ marginTop: "1em", marginRight: "4px", float: "left" }}
            onClick={handleStopGeneration}
            disabled={!isGenerating}
          >
            Stop generating
          </Button>
        </Col>
        <Col>
          <FeedbackButtons
            messages={messages}
            submitFeedback={chatHttpClient.submitFeedback}
            onHide={delayedFocusUserInput}
          />
        </Col>
      </Row>
      <Row>
        <textarea
          id="messageBox"
          placeholder="Type your message here. Press Enter to send your message. Press Shift+Enter for a newline"
          rows={3}
          type="text"
          value={userInput}
          onChange={(event) => setUserInput(event.target.value)}
          onKeyDown={handleUserInputKeydown}
          disabled={brains === null}
          ref={userInputRef}
          style={{ marginTop: "0.5em", marginBottom: "1em" }}
        />
      </Row>
    </Container>
  );
}
export default Chat;
