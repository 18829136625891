import { Row, Col } from "react-bootstrap";
import BotMessage from "./BotMessage";
import UserMessage from "./UserMessage";

function Message({ message, onHide }) {
  return (
    <Row style={{ margin: "1em" }}>
      {message.origin === "assistant" && (
        <Col md="auto" style={{ paddingRight: "10em" }}>
          <BotMessage message={message} onHide={onHide} />
        </Col>
      )}
      {message.origin === "user" && (
        <>
          <Col />
          <Col md="auto" style={{ paddingLeft: "10em" }}>
            <UserMessage message={message} />
          </Col>
        </>
      )}
    </Row>
  );
}
export default Message;
