import { Modal, Row, Col, Button, ListGroup } from "react-bootstrap";
import BotMessage from "./BotMessage";
import { useState } from "react";

function SourcesButton({ message, onHide }) {
  const [sourcesOpened, setSourcesOpened] = useState(false);

  return (
    <>
      <Button
        className="btn btn-secondary btn-sm d-inline-block"
        style={{ margin: "6px", alignSelf: "flex-start" }}
        onClick={() => setSourcesOpened(!sourcesOpened)}
      >
        Context
      </Button>

      <Modal
        show={sourcesOpened}
        onHide={() => {
          setSourcesOpened(false);
          onHide();
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Detailed information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <h5>Message:</h5>
            <Col md="auto" style={{ paddingRight: "10em" }}>
              <BotMessage message={message} hideSources={true} />
            </Col>
          </Row>
          <p />
          <Row>
            <h5>Context:</h5>
            <ListGroup>
              {message.sources.map((source, index) => (
                <ListGroup.Item
                  key={index}
                  style={{
                    marginBottom: "20px",
                    padding: "20px",
                    backgroundColor: "#f9f9f9",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    wordBreak: "break-word",
                  }}
                >
                  <a
                    href={source.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      fontWeight: "bold",
                      color: "#007BFF",
                      textDecoration: "none",
                    }}
                  >
                    {source.url}
                  </a>
                  <p
                    style={{
                      marginTop: "10px",
                      color: "#555",
                      fontSize: "1rem",
                      lineHeight: "1.6",
                    }}
                  >
                    {source.text.trim().replace(/(\s*\n\s*)+/g, "\n")}
                  </p>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default SourcesButton;
