import axios from "axios";
const serverUrlDyn = process.env.REACT_APP_BACKEND_URL;

class ChatHttpClient {
  async getBrains() {
    return axios
      .post(serverUrlDyn + "/get-brains")
      .then((res) => res.data)
      .catch((reas) => null);
  }

  async requestRag(content) {
    console.log(content);
    return axios
      .post(serverUrlDyn + "/rag", content)
      .then((res) => res.data)
      .catch((reas) => null);
  }

  async fetchStream(content) {
    console.log(content);
    return axios
      .post(serverUrlDyn + "/fetch-stream", content)
      .then((res) => res.data)
      .catch((reas) => null);
  }

  async submitFeedback(content) {
    console.log(content);
    return axios
      .post(serverUrlDyn + "/submit-feedback", content)
      .then((res) => res.data)
      .catch((reas) => null);
  }
}

export default ChatHttpClient;
