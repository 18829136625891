import { useEffect } from "react";

import Message from "./Message";

function Messages({ messages, windowId, onHide }) {
  const initialGreeting = `
  Hello,
  I am WeGPT Docs, a large language model chatbot.
  To get going, please select your desired interaction mode above and send your message.

  You can either select a *specific CAS documentation* and ask questions about that particular documentation.
  Or you can interact with me in a *chatbot only* manner where I do not have specific knowledge about CAS.
  
  Please be aware that as a language model I cannot reflect on the correctness of my answer. Do not rely on the information I give. To get correct information, please always check the context I provide in documentation mode.
  
  To provide feedback, feel free to use the *Feedback* button.

  (Tip: As I reflect on the current chat history to generate my responses, clearing the history can make me a bit faster and more accurate on new topics.)
`;

  const conditionalScrollDown = () => {
    const chatWindow = document.getElementById(windowId);
    const maxScroll = chatWindow.scrollHeight - chatWindow.clientHeight;
    const scrollTop = chatWindow.scrollTop;
    if (maxScroll - scrollTop < 60) {
      chatWindow.scrollTo({ top: chatWindow.scrollHeight, behavior: "smooth" });
    }
  };

  useEffect(conditionalScrollDown, [messages]);

  return (
    <>
      {messages && messages.length > 0 ? (
        messages.map((message) => <Message message={message} onHide={onHide} />)
      ) : (
        <Message
          message={{ origin: "assistant", text: initialGreeting }}
          onHide={onHide}
        />
      )}
    </>
  );
}
export default Messages;
