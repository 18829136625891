function NavigationBar() {
  return (
    <div
      style={{
        background: "#090749",
        color: "white",
        padding: "1em",
        marginBottom: "2em",
      }}
    >
      <b>WeGPT</b>
    </div>
  );
}
export default NavigationBar;
