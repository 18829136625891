import { Spinner, Toast } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import SourcesButton from "./SourcesButton";

function BotMessage({ message, onHide, hideSources }) {
  const isLoading = message.text === "";

  return (
    <Toast style={{ width: "100%" }}>
      <Toast.Header closeButton={false}>
        <strong className="me-auto">WeGPT Docs</strong>
        {message.duration_seconds && <small>{message.duration_seconds}s</small>}
      </Toast.Header>
      <Toast.Body>
        {isLoading ? (
          <Spinner animation="border" role="status" />
        ) : (
          <>
            <ReactMarkdown children={message.text} />
            {!hideSources && message.sources && (
              <SourcesButton message={message} onHide={onHide} />
            )}
          </>
        )}
      </Toast.Body>
    </Toast>
  );
}
export default BotMessage;
