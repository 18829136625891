import { Button, Dropdown } from "react-bootstrap";
import { FaLink } from "react-icons/fa";

function DocDropdown({
  brains,
  activeBrains,
  setActiveBrains,
  onSwitch,
  onHide,
  disabled,
}) {
  const chatbotOnlyName = "Chatbot Only";
  const allDocumentationName = "All Documentation";

  const identical = (brainList1, brainList2) => {
    const ids1 = new Set(brainList1.map((brain) => brain.id));
    const ids2 = new Set(brainList2.map((brain) => brain.id));

    return ids1.size === ids2.size && [...ids1].every((id) => ids2.has(id));
  };

  const contains = (brainList, target) => {
    return brainList.some((brain) => brain.id === target.id);
  };

  const sorted = (brainList) => {
    return [...brainList].sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  };

  const getActiveBrainsName = () => {
    if (activeBrains.length == 0) {
      return chatbotOnlyName;
    } else if (activeBrains.length == 1) {
      return activeBrains[0].name;
    } else {
      return allDocumentationName;
    }
  };

  const changeActiveBrains = (newBrains) => {
    if (identical(activeBrains, newBrains)) {
      return;
    }
    onSwitch();
    setActiveBrains(newBrains);
  };

  const onToggle = (isOpen) => {
    if (!isOpen) {
      onHide();
    }
  };

  return (
    <>
      {brains && (
        <Dropdown onToggle={onToggle}>
          <Dropdown.Toggle
            variant="success"
            id="dropdown-basic"
            disabled={disabled}
          >
            {getActiveBrainsName()}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              active={identical(activeBrains, [])}
              onClick={(event) => changeActiveBrains([])}
            >
              {chatbotOnlyName}
            </Dropdown.Item>
            <Dropdown.Divider />
            {sorted(brains).map((brain) => (
              <Dropdown.Item
                active={contains(activeBrains, brain)}
                onClick={(event) => changeActiveBrains([brain])}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "5px",
                }}
              >
                {brain.name}
                <a href={brain.url} target="_blank" rel="noopener noreferrer">
                  <Button
                    onClick={(event) => {
                      event.stopPropagation(); // Prevent dropdown from closing
                    }}
                    variant="link"
                    style={{
                      padding: "5px",
                      backgroundColor: "#f0f0f0",
                      border: "1px solid #ccc",
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                    }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <FaLink style={{ fontSize: "12px", color: "#007bff" }} />
                  </Button>
                </a>
              </Dropdown.Item>
            ))}
            <Dropdown.Divider />
            <Dropdown.Item
              active={identical(activeBrains, brains)}
              onClick={(event) => changeActiveBrains(brains)}
            >
              {allDocumentationName}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
      {!brains && (
        <span style={{ color: "red" }}>
          There was an error connecting to the server! Unable to present
          interaction modes.
        </span>
      )}
    </>
  );
}
export default DocDropdown;
