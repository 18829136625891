import { useEffect, useState, useRef } from "react";
import { Button, Modal, Form } from "react-bootstrap";

function FeedbackButtons({ messages, submitFeedback, onHide }) {
  const [showModal, setShowModal] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const feedbackRef = useRef(null);

  const lastNMessages = (n) => {
    let resultString = "";
    messages.slice(-n).forEach((message) => {
      resultString += message.origin + ":\n" + message.text + "\n";
    });
    return resultString;
  };

  const responseShortcut = (feedback, messageCount = 2) => {
    setFeedback(feedback + "\n\n" + lastNMessages(messageCount));
    setShowModal(true);
  };

  const handleFeedbackKeydown = (event) => {
    if (!(event.key === "Enter" && event.ctrlKey)) {
      return;
    }
    handleSubmit(event);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (feedback.trim() === "") {
      return;
    }

    try {
      const feedback_request = {
        feedback: feedback,
      };

      const feedback_response = await submitFeedback(feedback_request);

      if (feedback_response.success === true) {
        setResponseMessage("Thank you for your feedback!");
        setFeedback("");
        setTimeout(() => {
          setShowModal(false);
          setResponseMessage("");
        }, 2000);
      } else {
        setResponseMessage(
          "Feedback could not be saved successfully. Please try again."
        );
      }
    } catch (error) {
      setResponseMessage("An error occurred. Please try again.");
    }
  };

  useEffect(() => {
    // autofocus textarea when modal opens
    if (showModal) {
      feedbackRef.current?.focus();
    }
    if (!showModal) {
      onHide();
    }
  }, [showModal]);

  return (
    <>
      <Button
        className="btn btn-secondary btn-sm"
        id="feedbackButton"
        style={{ marginTop: "1em", marginRight: "4px", float: "right" }}
        onClick={() => setShowModal(true)}
      >
        Feedback
      </Button>
      <Button
        className="btn btn-secondary btn-sm"
        id="badResponseButton"
        style={{
          marginTop: "1em",
          marginRight: "4px",
          float: "right",
          backgroundColor: "lightcoral",
        }}
        onClick={() =>
          responseShortcut(
            "Bad Response! The answer did NOT fulfill my expectations!"
          )
        }
      >
        Bad Response
      </Button>
      <Button
        className="btn btn-secondary btn-sm"
        id="goodResponseButton"
        style={{
          marginTop: "1em",
          marginRight: "4px",
          float: "right",
          backgroundColor: "lightgreen",
        }}
        onClick={() =>
          responseShortcut(
            "Good Response! The answer fulfilled my expectations!"
          )
        }
      >
        Good Response
      </Button>

      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="feedbackText">
              <Form.Label>
                Feel free to share all your thoughts about WeGPT.
                <br />
                How well did it answer your question? Was the provided context
                what you were looking for?
                <br />
                We are happy to hear your suggestions, desired functions and
                what improvements you wish.
                <br />
                <br />
                Your Feedback is completely anonymous and helps a lot to
                identify which aspects to improve in the future.
              </Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Type your feedback here. Press Ctrl+Enter to send your message. Press Enter for a newline"
                rows={12}
                value={feedback}
                onChange={(event) => setFeedback(event.target.value)}
                onKeyDown={handleFeedbackKeydown}
                ref={feedbackRef}
                required
              />
            </Form.Group>
            <Button
              variant="primary"
              style={{ marginTop: "1em" }}
              type="submit"
            >
              Submit Feedback
            </Button>
          </Form>
          {responseMessage && <p className="mt-3">{responseMessage}</p>}
        </Modal.Body>
      </Modal>
    </>
  );
}
export default FeedbackButtons;
