import "./App.css";
import Chat from "./components/chat/chat";
import NavigationBar from "./components/nav/NavigationBar";
import { createContext } from "react";

import ChatHttpClient from "./components/chat/chatHttpClient";

export const ChatHttpClientContext = createContext(ChatHttpClient);

function App() {
  return (
    <>
      <NavigationBar />
      <ChatHttpClientContext.Provider value={new ChatHttpClient()}>
        <Chat />
      </ChatHttpClientContext.Provider>
    </>
  );
}

export default App;
